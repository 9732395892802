import React, { Fragment } from "react";
import { Element } from "react-scroll";
import HighlightPop from "react-highlight-pop";
import TaskNotes from "../task-notes/task-notes.component";
import GoogleAd from "../google-ad/google-ad.component";
import { useState } from "react";
import { useEffect } from "react";
import Cookies from "js-cookie";

import {
  getUserCompletedTasks,
  setUserCompletedTasks,
  getRealtimeTaskUpdates,
} from "../../firebase/firebase.utils";

import "./section.scss";
import styled from "styled-components";

const CompletedCheckmark = styled.span`
  &:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid ${(props) => props.color};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:hover {
    background-color: ${(props) => props.color};
  }
  .steps:hover input ~ & {
    background-color: ${(props) => props.color};
  }
  .container:hover input ~ & {
    background-color: ${(props) => props.color};
  }
`;

const Section = ({ id, title, tasks, userId, currentColor }) => {
  const [completedTasks, setCompletedTasks] = useState([]);
  const [taskList, setTaskList] = useState(tasks);

  useEffect(() => {
    if (userId) {
      const getTasks = async () => {
        let completedTasks = await getUserCompletedTasks(userId);
        setCompletedTasks(completedTasks);
      };
      getTasks();

      const unsubscribe = getRealtimeTaskUpdates(userId, (doc) => {
        setCompletedTasks(doc.data().completedTasks);
      });
      return () => unsubscribe();
    } else {
      let completedTasksJSON = Cookies.get("completed-tasks");
      if (completedTasksJSON) {
        setCompletedTasks(JSON.parse(completedTasksJSON));
      }
    }
  }, [userId]);

  const searchWiki = () => {
    let searchTerm = window.getSelection().toString();
    let URL =
      "https://oldschool.runescape.wiki/w/Special:Search?search=" + searchTerm;
    window.open(URL, "_blank");
  };

  const updateTask = (event, task) => {
    let newCompletedTasks;
    task.completed = event.target.checked;
    if (event.target.checked) {
      newCompletedTasks = completedTasks.concat(event.target.id);
    } else {
      newCompletedTasks = completedTasks;
      const index = newCompletedTasks.indexOf(event.target.id);
      if (index > -1) {
        newCompletedTasks.splice(index, 1);
      }
    }
    const itemsToUpdate = tasks.map((task) => {
      if (task.id === parseInt(event.target.id)) {
        task.completed = event.target.checked;
      }
      return task;
    });
    setTaskList(itemsToUpdate);
    setCompletedTasks(newCompletedTasks);
    if (userId) {
      setUserCompletedTasks(userId, newCompletedTasks);
    } else {
      Cookies.set("completed-tasks", JSON.stringify(newCompletedTasks), {
        expires: 9999,
      });
    }
  };

  return (
    <Element name={title} key={id}>
      <GoogleAd />
      <h3>{title}</h3>
      <HighlightPop
        popoverItems={() => (
          <Fragment>
            <img
              src={process.env.PUBLIC_URL + "/WIKI.png"}
              alt="inventory icon"
              onClick={searchWiki}
              style={{ cursor: "pointer" }}
            ></img>
          </Fragment>
        )}
      >
        {completedTasks &&
          taskList.map((task, index) => {
            const isChecked = completedTasks.includes(String(task.id));
            return (
              <div key={index} className="task">
                <label className="container">
                  <div className="idtext">
                    <label className="id">
                      <small>{task.id}</small>
                    </label>
                  </div>
                  <input
                    name={`task-${task.id}`}
                    id={task.id}
                    type="checkbox"
                    checked={isChecked}
                    onChange={(event) => updateTask(event, task)}
                  />
                  <label className="steps">{task.text}</label>
                  <CompletedCheckmark
                    color={currentColor}
                    className="checkmark"
                  />
                </label>
                {task.notes && (
                  <TaskNotes notes={task.notes} taskId={task.id} />
                )}
              </div>
            );
          })}
      </HighlightPop>
    </Element>
  );
};

export default Section;
