import React from "react";
import { Link } from "react-scroll";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import styled from "styled-components";
import "./sidebar.scss";

// Icons
import { FaRunning, FaHome, FaPrayingHands } from "react-icons/fa";
import {
  GiFrostfire,
  GiSpearfishing,
  GiFairy,
  GiFlowerStar,
  GiGloves,
  GiDaemonSkull,
  GiSnake,
} from "react-icons/gi";

const icons = {
  FaHome: <FaHome />,
  FaPrayingHands: <FaPrayingHands />,
  giFrostfire: <GiFrostfire />,
  GiSpearfishing: <GiSpearfishing />,
  GiFlowerStar: <GiFlowerStar />,
  GiDaemonSkull: <GiDaemonSkull />,
  GiGloves: <GiGloves />,
  GiFairy: <GiFairy />,
  GiSnake: <GiSnake />,
  FaRunning: <FaRunning />,
};

const ColoredLink = styled(Link)`
  &.active{
      color: ${props => props.color} !important;
  }
`;

const Sidebar = ({ sections, currentColor }) => {

  return (
    <ProSidebar breakPoint="md" className="sidebar">
      <Menu iconShape="square">
        <MenuItem icon={icons.FaHome}>
          <ColoredLink
            activeClass="active"
            spy={true}
            to="home"
            smooth={"easeOutQuint"}
            offset={-70}
            duration={500}
            color={currentColor}
          >
            Home
          </ColoredLink>
        </MenuItem>
        {sections.map((item, index) => {
          return (
            <MenuItem key={index} icon={icons[item.icon]}>
              <ColoredLink
                activeClass="active"
                spy={true}
                to={item.title}
                smooth={"easeOutQuint"}
                offset={-70}
                duration={500}
                color={currentColor}
              >
                {item.title}
              </ColoredLink>
            </MenuItem>
          );
        })}
      </Menu>
    </ProSidebar>
  );
};

export default Sidebar;
