import ReactPlayer from "react-player";
import "./task-notes.scss";

const TaskNotes = ({ notes, taskId }) => {
  const getContent = (note, noteIndex) => {
    switch (note.type) {
      case "normal":
        return (
          <div className="notes">
            <img
              src={process.env.PUBLIC_URL + "/notes.png"}
              alt="inventory icon"
            ></img>
            {Array.isArray(note.content) ? (
              note.content.map((content, contentIndex) => (
                <div key={`${taskId}-note-${noteIndex}-content${contentIndex}`}>
                  {content}
                </div>
              ))
            ) : (
              <span>{note.content}</span>
            )}
          </div>
        );
      case "inventory":
        return (
          <div className="inventory">
            <img
              src={process.env.PUBLIC_URL + "/backpack.png"}
              alt="inventory icon"
            ></img>
            <span>{note.content}</span>
          </div>
        );
      case "hardcore":
        return (
          <div className="hardcore">
            <img
              src={process.env.PUBLIC_URL + "/hardcore_ironman.png"}
              alt="hardcore icon"
            ></img>
            <span>{note.content}</span>
          </div>
        );
      case "screenshot":
        return (
          <div>
            <img src={note.content} alt="screenshot" />
          </div>
        );
      case "equipment":
        return (
          <div className="equipment">
            <img
              src={process.env.PUBLIC_URL + "/equip.png"}
              alt="equipment icon"
            ></img>
            <span>{note.content}</span>
          </div>
        );
      case "video":
        return (
          <div className="video">
            <ReactPlayer url={note.content} controls={true} height="100%" width="100%"/>
          </div>
        );
        case "none":
          return (
            <div className="notes">
            {Array.isArray(note.content) ? (
              note.content.map((content, contentIndex) => (
                <div key={`${taskId}-note-${noteIndex}-content${contentIndex}`}>
                  {content}
                </div>
              ))
            ) : (
              <span>{note.content}</span>
            )}
          </div>
          );
      default:
        return <h3>No component exists for type {note.type}</h3>;
    }
  };

  return notes.map((note, noteIndex) => (
    <div key={`${taskId}-note-${noteIndex}`}>{getContent(note, noteIndex)}</div>
  ));
};

export default TaskNotes;
