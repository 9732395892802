import React, { useState, useEffect } from "react";

// Utilities

import { auth, createUserProfileDocument } from "./firebase/firebase.utils";

// Components
import Content from "./components/content/content.component";
import Header from "./components/header/header.component";
import Sidebar from "./components/sidebar/sidebar.component";

// CSS Styles
import "react-pro-sidebar/dist/css/styles.css";
import "./App.scss";

import content from "./data/content.json";
import Cookies from "js-cookie";

const App = () => {
  const [themeColor, setThemeColor] = useState("#ff8800");
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        createUserProfileDocument(userAuth)
          .then((userSnap) => {
            setCurrentUser({
              id: userSnap.id,
              ...userSnap.data(),
            });
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        setCurrentUser(null);
      }
    });
    const themeColor = Cookies.get("theme-color");
    if (themeColor) {
      setThemeColor(themeColor);
    }
  }, []);

  return (
    <div className="main">
      <Header
        currentColor={themeColor}
        onColorChange={(hex) => {
          setThemeColor(hex);
          Cookies.set("theme-color", hex, { expires: 9999 });
        }}
        currentUser={currentUser}
      />

      <Sidebar sections={content.sections} currentColor={themeColor} />

      <Content currentColor={themeColor} user={currentUser} />
    </div>
  );
};

export default App;

//The book icon represents general notes that you should be aware of. The color of the text will be classic OSRS yellow.
//The backpack icon show when you should be carrying certain items with you as you go around. The color of this text is orange.
//The equipment icon will tell you when you need to have certain items equipped. The color of this text will be purple.
//The hardcore ironman symbol will be used whenever there is a specific item or path someone should take if they want to keep their status.
