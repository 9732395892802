import React, { useEffect, useState } from "react";
import SearchBox from "../search-box/searchbox.component";
import image from "../../LElogo.png";
import { SketchPicker } from "react-color";
import Tippy from "@tippyjs/react";
import styled from "styled-components";

import {
  signInWithGoogle,
  auth,
  updateDisplayName,
} from "../../firebase/firebase.utils";

import "./header.scss";
const SignInButton = styled.button`
  color: white;
  background: transparent;
  border: 2px solid white;
  padding: 5px 15px;
  margin-left: 10px;
  &:hover {
    transition-duration: 0.4s;
    color: ${(props) => props.highlightColor};
    border: 2px solid ${(props) => props.highlightColor};
    cursor: pointer;
  }
`;
const SignOutButton = styled.button`
  color: #3c4752;
  background: transparent;
  border: 2px solid #3c4752;
  padding: 5px 15px;
  &:hover {
    transition-duration: 0.4s;
    color: ${(props) => props.highlightColor};
    border: 2px solid ${(props) => props.highlightColor};
    cursor: pointer;
  }
`;
const Header = ({ currentColor, onColorChange, currentUser }) => {
  const [selectedColor] = useState("#ff8800");
  const [editMode, setEditMode] = useState(false);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    if(currentUser) {
      setDisplayName(currentUser.displayName);
    }
  }, [currentUser]);

  return (
    <div className="header">
      <div style={{ display: "flex" }}>
        <img src={image} alt="logo"></img>
        <SearchBox themeColor={currentColor} />
      </div>
      <div className="app">
        <div className="area" style={{ backgroundColor: selectedColor }}></div>
        <Tippy
          trigger={"click"}
          interactive={true}
          placement={"bottom"}
          content={
            <SketchPicker
              disableAlpha={true}
              color={currentColor}
              onChangeComplete={(color) => onColorChange(color.hex)}
            />
          }
        >
          <button
            className="ref-button"
            style={{ backgroundColor: currentColor }}
          ></button>
        </Tippy>
        {currentUser ? (
          <>
            {editMode ? (
              <>
                <input className="namebox"
                  type="text"
                  defaultValue={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                    updateDisplayName(currentUser.id, e.target.value);
                  }}
                />
                <button className="savebutton"
                  onClick={() => {
                    setEditMode(false);
                  }}
                >
                  save
                </button>
              </>
            ) : (
              <span
                className="display-name"
                onDoubleClick={() => setEditMode(true)}
              >
                {displayName}
              </span>
            )}
            <SignOutButton
              className="signout"
              variant="outlined"
              highlightColor={currentColor}
              onClick={() => auth.signOut()}
            >
              Sign out
            </SignOutButton>
          </>
        ) : (
          <>
            <SignInButton
              className="signin"
              variant="outlined"
              highlightColor={currentColor}
              onClick={() => signInWithGoogle()}
            >
              Sign in
            </SignInButton>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
