// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  getDoc,
  setDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import Cookies from "js-cookie";

// Your web app's Firebase configuration
const config = {
  apiKey: "AIzaSyDzEI4mq_BbMnhPHfq54KCDvHA4l4PH1jw",
  authDomain: "rs-ironman.firebaseapp.com",
  projectId: "rs-ironman",
  storageBucket: "rs-ironman.appspot.com",
  messagingSenderId: "940369579764",
  appId: "1:940369579764:web:0306f88b277f25bbbd18a1",
  measurementId: "G-NB03BZ9ELQ",
};

// Initialize Firebase
const app = initializeApp(config);
getAnalytics(app);
const db = getFirestore();
export const auth = getAuth();

const provider = new GoogleAuthProvider();
provider.setCustomParameters({ prompt: "select_account" });

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider).catch((error) => {
    console.error(error);
  });
};

export const createUserProfileDocument = async (userAuth) => {
  if (!userAuth) {
    return;
  }

  const { uid, displayName, email } = userAuth;
  const createdAt = new Date();

  const userRef = doc(db, "users", uid);
  const userDoc = await getDoc(userRef);
  if(!userDoc.exists()) {
    const completedTasks = Cookies.get('completed-tasks') || "[]";
    await setDoc(
      userRef,
      {
        displayName,
        email,
        createdAt,
        completedTasks: JSON.parse(completedTasks),
      },
      { merge: true }
    );
  }

  return await getDoc(userRef);
};

export const getUserCompletedTasks = async (userId) => {
  if (!userId) {
    return;
  }

  const userRef = doc(db, "users", userId);
  const userDoc = await await getDoc(userRef);
  const { completedTasks } = userDoc.data();
  return completedTasks;
};

export const setUserCompletedTasks = async (userId, completedTasks) => {
  if (!userId) {
    return;
  }

  const userRef = doc(db, "users", userId);
  await setDoc(
    userRef,
    {
      completedTasks,
    },
    { merge: true }
  );

  return await getDoc(userRef);
};

export const updateDisplayName = async (userId, displayName) => {
  if (!userId) {
    return;
  }

  const userRef = doc(db, "users", userId);
  await setDoc(
    userRef,
    {
      displayName,
    },
    { merge: true }
  );

  return await getDoc(userRef);
};

export const getRealtimeTaskUpdates = (userId, callback) => {
  return onSnapshot(doc(db, "users", userId), callback);
};

export default app;
