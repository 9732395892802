import { useEffect } from "react";

const GoogleAd = () => {
  useEffect(() => {
    const adsByGoogle = window.adsbygoogle || [];
    adsByGoogle.push({});
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block", textAlign: "center" }}
      data-ad-layout="in-article"
      data-ad-format="fluid"
      data-ad-client="ca-pub-4330451393580755"
      data-ad-slot="8805952592"
    />
  );
};

export default GoogleAd;