import React, { useState } from "react";
import { Link } from "react-scroll";
import data from "../../data/content.json";
import Highlighter from "react-highlight-words";

import "./searchbox.scss";
import styled from "styled-components";

const StyledHighlighter = styled(Highlighter)`
  & > mark.highlighted{
    background-color: ${props => props.color} !important;
  }
`;
const ResultsList = styled.ul`
margin: 0;
list-style: none;
padding: 0 15px;   
max-height: 400px;
overflow-y: scroll;
&::-webkit-scrollbar-thumb:hover {
    background: ${props => props.color};
  }`
const SearchBox = ({themeColor}) => {
  const [tasksFound, setTasksFound] = useState([]);
  const [showResults, setShowResults] = useState(false);

  const handleSearch = (e) => {
    const searchValue = e.target.value;
    if (searchValue.length < 3) {
      setShowResults(false);
      setTasksFound([]);
      return;
    }
    setShowResults(true);

    const tasks = data.sections.flatMap((section) => {
      return section.tasks
        .filter((task) => {
          if (task !== undefined && !Array.isArray(task.text)) {
            const taskTextLower = task.text.toLowerCase();
            const searchTextLower = searchValue.toLowerCase();
            return taskTextLower.includes(searchTextLower);
          }
          return false;
        })
        .map((task) => {
          console.log(task.text);
          return {
            id: task.id,
            text: (
              <StyledHighlighter
                color={themeColor}
                highlightClassName="highlighted"
                searchWords={[searchValue]}
                autoEscape={true}
                textToHighlight={task.text}
              />
            ),
          };
        });
    });

    setTasksFound(tasks);
  };

  return (
    <div>
      <input className="searchBox"
        type="search"
        name="search-text"
        onChange={(e) => handleSearch(e)}
        onFocus={() => setShowResults(true)}
        onBlur={() => setTimeout(()=>{ setShowResults(false)}, 100)}
        placeholder="Search..."
      />
      {tasksFound.length > 0 && showResults && (
        <div id="search-results-box">
          <ResultsList color={themeColor}>
            {tasksFound.map((task) => (
              <li className="search-link">
                
                <Link
                  key={`search-result-${task.id}`}
                  to={`task-${task.id}`}
                  onClick={() => setShowResults(false)}
                  spy={true}
                  smooth={"easeOutQuint"}
                  offset={-70}
                  duration={500}
                ><span className="task-id">Task {task.id}</span>
                  {task.text}
                </Link>
              </li>
            ))}
          </ResultsList>
        </div>
      )}
    </div>
  );
};

export default SearchBox;
