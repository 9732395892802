import React, { useState } from 'react';
import { Element } from 'react-scroll';
import Section from '../../components/section/section.component';
import './os-font.css';
import './content.scss';
import data from '../../data/content.json';
import styled from 'styled-components';


const Title = styled.h2`
    color: ${props => props.color}
`;

const Content = ({currentColor, user}) => {

    const [sections] = useState(data.sections);

    return (
        <div className="content-wrapper">
            <Element name="home">
                <Title color={currentColor}>Ironman Efficiency Guide</Title>
                <p>Welcome to the Ironman Efficiency Guide. The content in this guide was written by Oziris. You can find him <a className="oziris" href="https://twitter.com/OzirisLoL">@OzirisLoL</a> on Twitter.</p>
                <p>If you find this guide helpful to you, please consider donating to him on <a className="oziris" href="paypal.me/ozirisrs">Paypal</a>. </p>
                <p id="tutorial">Throughout the guide you will see icons and text colors to help you see important items or notes that you should be aware of:</p>
                <div className="keynotes">
                    <p style={{'color':'#FFFF00'}}><img className="key" src={process.env.PUBLIC_URL + '/notes.png'} alt="notes"></img>The book icon represents general notes that you should be aware of. The color of the text will be classic OSRS yellow.</p>
                    <p style={{'color':'rgb(255, 123, 0)'}}><img className="key" src={process.env.PUBLIC_URL + '/backpack.png'} alt="backpack"></img>The backpack icon show when you should be carrying certain items with you as you go around. The color of this text is orange. </p>
                    <p style={{'color':'#cc00ff'}}><img className="key" src={process.env.PUBLIC_URL + '/equip.png'} alt="equipment"></img>The equipment icon will tell you when you need to have certain items equipped. The color of this text will be purple.</p>
                    <p style={{'color':'#ac1b1b'}}><img className="hc" src={process.env.PUBLIC_URL + '/hardcore_ironman.png'} alt="hardcore ironman"></img>The hardcore ironman symbol, along with red text, will be used whenever an activity is dangerous.</p>
                </div>
                <p id="tutorial">You are able to save progress on your ocmputer locally, through the use of cookies. Just be careful not to clear them or you will lose all of your progress. You can also login through Google and save your progress. With this, you will be able to track your progress on any device and they will all sync.</p>
                <p>Usernames can be edited by double clicking on them, editing the existing name, then saving afterward.</p>

                <div className="gifnotes">
                        <p><img className='gifdemo' src={process.env.PUBLIC_URL + '/demogif.gif'} alt="demo"></img>We have also added a handy feature to quickly search for items/people/places you may not know. Just highlight some 
                        text by dragging your mouse over it and click the OSRS Wiki icon to search the wiki right from the guide.
                        It searches the exact thing you highlight, so make sure not to highlight punctuation or spaces to help the search find exactly what you are looking for.
                        </p>
                 </div>
                 <div className="finalnotes">
                        <p>We have enjoyed putting this together and hope that you find good use out of it. There are some current issues that we are working on, but we wanted to get this out in time for Group Iron. If you find bugs, typos or just have some comments, contact me on <a className="oziris" href="https://twitter.com/The_DemiNerd/">Twitter</a>. Thanks again. Good luck and we will see you in Gielinor.
                        </p>
                 </div>
                   
            </Element>

            {sections.map((section) => {
                const {id, ...otherSectionProps} = section;
                if (user) {
                    return <Section key={id} userId={user.id} currentColor={currentColor} {...otherSectionProps} />
                }
                else {
                    return <Section key={id} currentColor={currentColor} {...otherSectionProps} />
                }
            })}
        </div>
    );
}

export default Content;
